import { Diagnosis } from "@/diagnosis/Diagnosis"
import { MarkerName } from "./marker/MarkerName"
import { getShortName } from "./scene/SceneAlias"
import { SceneName } from "./scene/SceneName"


export const getDomain = () => {
    const url = new URL(window.location.href)
    if (process.env.VUE_APP_DOMAIN) {
        return `${url.protocol}//${process.env.VUE_APP_DOMAIN}`
    }
    return `${url.protocol}//${url.hostname}`
}

const getPath = (s: string, m: MarkerName) => {
    return `?_scene=${s}&m=${m}`
}

export const diagnosisURL = (diagnosis: Diagnosis) => {
    const domain = getDomain()
    const query = getPath(getShortName(SceneName.thirdfloorleft), MarkerName.tbp)
    // 辛いから固定で書く
    const path = `${domain}${query}&r=${diagnosis.id}`
    console.log('diagnosis url', path)
    return path
}
