export enum SceneName {
    load = 'load',
    load_to_firstfloorcenter = 'load_to_firstfloorcenter',
    firstfloorcenter = 'firstfloorcenter',
    firstfloorcenter_to_secondfloorcenter = 'firstfloorcenter_to_secondfloorcenter',
    secondfloorcenter = 'secondfloorcenter',
    secondfloorcenter_to_secondfloorleft = 'secondfloorcenter_to_secondfloorleft',
    secondfloorleft = 'secondfloorleft',
    secondfloorleft_to_secondfloorcenter = 'secondfloorleft_to_secondfloorcenter',
    secondfloorcenter_to_secondfloorright = 'secondfloorcenter_to_secondfloorright',
    secondfloorright = 'secondfloorright',
    secondfloorright_to_secondfloorcenter = 'secondfloorright_to_secondfloorcenter',
    secondfloorcenter_to_thirdfloorcenter  = 'secondfloorcenter_to_thirdfloorcenter',
    thirdfloorcenter = 'thirdfloorcenter',
    thirdfloorcenter_to_thirdfloorleft= 'thirdfloorcenter_to_thirdfloorleft',
    thirdfloorleft= 'thirdfloorleft',
    thirdfloorleft_to_thirdfloocenter= 'thirdfloorleft_to_thirdfloocenter',
    thirdfloorcenter_to_thirdfloorright= 'thirdfloorcenter_to_thirdfloorright',
    thirdfloorright= 'thirdfloorright',
    thirdfloorright_to_thirdfloocenter= 'thirdfloorright_to_thirdfloocenter',
    thirdfloorcenter_to_fourthfloorcenter= 'thirdfloorcenter_to_fourthfloorcenter',
    fourthfloorcenter= 'fourthfloorcenter',
    fourthfloorcenter_to_fourthfloorleft= 'fourthfloorcenter_to_fourthfloorleft',
    fourthfloorleft= 'fourthfloorleft',
    fourthfloorleft_to_fourthfloocenter= 'fourthfloorleft_to_fourthfloocenter',
    fourthfloorcenter_to_fourthfloorright= 'fourthfloorcenter_to_fourthfloorright',
    fourthfloorright= 'fourthfloorright',
    fourthfloorright_to_fourthfloocenter= 'fourthfloorright_to_fourthfloocenter',
    fourthfloorcenter_to_fifthfloorcenter= 'fourthfloorcenter_to_fifthfloorcenter',
    fifthfloorcenter= 'fifthfloorcenter',
    fifthfloorcenter_to_fifthfloorleft= 'fifthfloorcenter_to_fifthfloorleft',
    fifthfloorleft= 'fifthfloorleft',
    fifthfloorleft_to_fifthfloocenter= 'fifthfloorleft_to_fifthfloocenter',
    fifthfloorcenter_to_fifthfloorright= 'fifthfloorcenter_to_fifthfloorright',
    fifthfloorright= 'fifthfloorright',
    fifthfloorright_to_fifthfloocenter= 'fifthfloorright_to_fifthfloocenter',
    fifthfloorcenter_to_fourthfloorcenter= 'fifthfloorcenter_to_fourthfloorcenter',
    fourthfloorcenter_to_thirdfloorcenter= 'fourthfloorcenter_to_thirdfloorcenter',
    thirdfloorcenter_to_secondfloorcenter= 'thirdfloorcenter_to_secondfloorcenter',
    secondfloorcenter_to_firstfloorcenter= 'secondfloorcenter_to_firstfloorcenter',
  }
  