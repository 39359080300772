import { SceneName } from "./SceneName"

const sceneAlias = new Map<string, SceneName>([
    ['3FL', SceneName.thirdfloorcenter]
])

const shortNameAlias = new Map<SceneName, string>([
    [SceneName.thirdfloorleft, '3FL']
])

export const getSceneName = (s:string) => {
    let sceneName = SceneName[s]
    if(sceneName){
        return sceneName
    }

    return sceneAlias.get(s)
}

export const getShortName = (s: SceneName) => {
    const shortName = shortNameAlias.get(s)
    return shortName ? shortName : s
}