import { Product } from "@/product/Product"
import { FavoriteProduct, FavoriteProductImpl } from "./FavoriteProduct"
import { ProductArea } from "@/product/ProductArea"

export class FavList {
  private static _instance: FavList
  products?: Array<FavoriteProduct>

  private constructor() {
    this.products = []
    this.init()
  }

  public static get instance(): FavList {
    if(!this._instance) {
      this._instance = new FavList()
    }
    return this._instance
  }

  public add(product: Product, productArea: ProductArea) {
    const favoriteProduct: FavoriteProduct = new FavoriteProductImpl(
      product.id,
      product.jaName,
      product.imgSrc,
      product.url.get(productArea)
    )
    const sameProduct = this.products.find(p => p.id === favoriteProduct.id)
    if(sameProduct) {
      this.remove(favoriteProduct)
    }
    this.products.unshift(favoriteProduct)
    this.setLocalStorage(favoriteProduct)
  }

  public remove(product: FavoriteProduct) {
    console.log('remove', product)
    const products = this.products.filter(p => p.id !== product.id)
    this.setProducts(products)
    this.removeLocalStorage(product)
  }

  public get(_product: Product) {
    const product: FavoriteProduct = this.products.find(p => p.id === _product.id)
    return product
  }

  public setProducts(products: Array<FavoriteProduct>) {
    this.products = products
  }

  public init() {
    const productsStr: string = localStorage.getItem('favProducts')
    if(productsStr){
      const productArray: Array<string> = productsStr.split('|')
      let products: Array<FavoriteProduct> = []
      products = this.jsonParse(productArray, products)
      if(!products) {
        return
      }
      this.setProducts(products)
    }
  }

  private setLocalStorage(product: FavoriteProduct) {
    const productsStr: string = localStorage.getItem('favProducts')
    const productStr: string = this.jsonStringify(product)
    if(!productStr) {
      return
    }
    let newProductsStr: string = productStr
    if(productsStr) {
      newProductsStr = productStr + '|' + productsStr
    }
    localStorage.setItem('favProducts', newProductsStr)
  }

  private removeLocalStorage(product: FavoriteProduct) {
    const productsStr: string = localStorage.getItem('favProducts')
    if(productsStr.includes('|')) {
      const productArray: Array<string> = productsStr.split('|')
      let products: Array<FavoriteProduct> = []
      products = this.jsonParse(productArray, products)
      if(!products) {
        return
      }
      const newArray: Array<FavoriteProduct> = products.filter(p => p.id !== product.id)
      const newStrs: Array<string> = newArray.map(p => {
        return JSON.stringify(p)
      })
      const newStr: string = newStrs.join('|')
      localStorage.setItem('favProducts', newStr)
    } else {
      localStorage.clear()
    }
  }

  private jsonParse(productArray: Array<string>, products: Array<FavoriteProduct>) {
    for(let i = 0; i < productArray.length; i++) {
      try {
        const p: FavoriteProduct = JSON.parse(productArray[i])
        products.push(p)
      } catch(e) {
        console.log('json.parse_error', e.message)
        localStorage.clear()
        products = undefined
        break
      }
    }
    return products
  }

  private jsonStringify(product: FavoriteProduct) {
    let productStr: string
    try {
      productStr = JSON.stringify(product)
    } catch(e) {
      console.log('json.stringify_error', e.message)
      localStorage.clear()
      productStr = undefined
    }
    return productStr
  }
}