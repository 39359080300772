export const TEST = 0

export const MAPINFO = {
    // mapの縦と横
    vertical: 13,
    beside: 13,
    // vertical: 26,
    // beside: 26,
    // mapの１タイルの大きさ
    tile: 30,
    // // beside*tile
    dotSize: 3,
}

export enum DIRECTION {
    up = 1,
    down = 2,
    left = 3,
    right = 4,
    stay = 0
}

export enum MAPOBJECT {
    blank,
    wall,
    dot,
    eaten,
    pure
}

export const map: number[][] = [
    [MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.wall],
    [MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.dot, MAPOBJECT.dot, MAPOBJECT.dot, MAPOBJECT.dot, MAPOBJECT.dot, MAPOBJECT.dot, MAPOBJECT.dot, MAPOBJECT.dot, MAPOBJECT.dot, MAPOBJECT.wall, MAPOBJECT.wall],
    [MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.dot, MAPOBJECT.wall, MAPOBJECT.pure, MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.dot, MAPOBJECT.wall, MAPOBJECT.pure, MAPOBJECT.wall, MAPOBJECT.wall],
    [MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.dot, MAPOBJECT.wall, MAPOBJECT.dot, MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.dot, MAPOBJECT.wall, MAPOBJECT.dot, MAPOBJECT.wall, MAPOBJECT.wall],
    [MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.dot, MAPOBJECT.wall, MAPOBJECT.dot, MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.dot, MAPOBJECT.wall, MAPOBJECT.dot, MAPOBJECT.wall, MAPOBJECT.wall],
    [MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.pure, MAPOBJECT.wall, MAPOBJECT.dot, MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.dot, MAPOBJECT.wall, MAPOBJECT.dot, MAPOBJECT.wall, MAPOBJECT.wall],
    [MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.dot, MAPOBJECT.wall, MAPOBJECT.dot, MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.pure, MAPOBJECT.wall, MAPOBJECT.dot, MAPOBJECT.wall, MAPOBJECT.wall],
    [MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.dot, MAPOBJECT.wall, MAPOBJECT.dot, MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.dot, MAPOBJECT.wall, MAPOBJECT.dot, MAPOBJECT.wall, MAPOBJECT.wall],
    [MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.dot, MAPOBJECT.wall, MAPOBJECT.dot, MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.dot, MAPOBJECT.wall, MAPOBJECT.dot, MAPOBJECT.wall, MAPOBJECT.wall],
    [MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.dot, MAPOBJECT.wall, MAPOBJECT.pure, MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.dot, MAPOBJECT.wall, MAPOBJECT.dot, MAPOBJECT.wall, MAPOBJECT.wall],
    [MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.dot, MAPOBJECT.wall, MAPOBJECT.dot, MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.dot, MAPOBJECT.wall, MAPOBJECT.dot, MAPOBJECT.wall, MAPOBJECT.wall],
    [MAPOBJECT.blank, MAPOBJECT.blank, MAPOBJECT.dot, MAPOBJECT.dot, MAPOBJECT.dot, MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.dot, MAPOBJECT.dot, MAPOBJECT.pure, MAPOBJECT.blank, MAPOBJECT.blank],
    [MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.wall, MAPOBJECT.wall],
]

// export const map: number[][] = [
//     [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
//     [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
//     [1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 1, 1, 1, 1, 1],
//     [1, 1, 1, 1, 2, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 2, 1, 1, 1, 2, 1, 1, 1, 1, 1],
//     [1, 1, 1, 1, 2, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 2, 1, 1, 1, 2, 1, 1, 1, 1, 1],
//     [1, 1, 1, 1, 2, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 2, 1, 1, 1, 2, 1, 1, 1, 1, 1],
//     [1, 1, 1, 1, 2, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 2, 1, 1, 1, 2, 1, 1, 1, 1, 1],
//     [1, 1, 1, 1, 2, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 2, 1, 1, 1, 2, 1, 1, 1, 1, 1],
//     [1, 1, 1, 1, 2, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 2, 1, 1, 1, 2, 1, 1, 1, 1, 1],
//     [1, 1, 1, 1, 2, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 2, 1, 1, 1, 2, 1, 1, 1, 1, 1],
//     [1, 1, 1, 1, 2, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 2, 1, 1, 1, 2, 1, 1, 1, 1, 1],
//     [1, 1, 1, 1, 2, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 2, 1, 1, 1, 2, 1, 1, 1, 1, 1],
//     [1, 1, 1, 1, 2, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 2, 1, 1, 1, 2, 1, 1, 1, 1, 1],
//     [1, 1, 1, 1, 2, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 2, 1, 1, 1, 2, 1, 1, 1, 1, 1],
//     [1, 1, 1, 1, 2, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 2, 1, 1, 1, 2, 1, 1, 1, 1, 1],
//     [1, 1, 1, 1, 2, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 2, 1, 1, 1, 2, 1, 1, 1, 1, 1],
//     [1, 1, 1, 1, 2, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 2, 1, 1, 1, 2, 1, 1, 1, 1, 1],
//     [1, 1, 1, 1, 2, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 2, 1, 1, 1, 2, 1, 1, 1, 1, 1],
//     [1, 1, 1, 1, 2, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 2, 1, 1, 1, 2, 1, 1, 1, 1, 1],
//     [1, 1, 1, 1, 2, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 2, 1, 1, 1, 2, 1, 1, 1, 1, 1],
//     [1, 1, 1, 1, 2, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 2, 1, 1, 1, 2, 1, 1, 1, 1, 1],
//     [1, 1, 1, 1, 2, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 2, 1, 1, 1, 2, 1, 1, 1, 1, 1],
//     [0, 0, 0, 0, 2, 2, 2, 2, 2, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 0, 0, 0, 0, 1],
//     [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],

// ]

