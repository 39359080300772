export interface Prize {
  name: string;
  code: string;
  src?: string;
  srcs?: Array<string>;
  url: string;
}
export const prize_list = new Map<string, Prize>([
  [
    'prize1',
    {
      name: '<span class="no-wrap">ポーチ＆ノート</span> <span class="no-wrap">選べる全4種</span>',
      code: '4VB1',
      srcs: [
        './assets/img/game/img_game_prize_1_1.png',
        './assets/img/game/img_game_prize_1_2.png',
        './assets/img/game/img_game_prize_1_3.png',
        './assets/img/game/img_game_prize_1_4.png'
      ],
      url: 'test_pouch'
    }
  ],
  ['prize2',
    {
      name: 'トップ シークレット<br/>クレンジング ウォーター (50mL)',
      code: '5VB8',
      src: './assets/img/game/img_game_prize_2.png',
      url: 'https://www.yslb.jp/skincare/skincare-category/skincare-cleansers/top-secrets-instant-makeup-remover/JJ-10045YSL.html?source=virtual_beautyzone&campaign=virtual_beautyzone_game_tpscw'
    }],
  ['prize3',
    {
      name: 'ピュアショット UV50<br/>ミニサイズ (3mL)',
      code: '6VB6',
      src: './assets/img/game/img_game_prize_3.png',
      url: 'https://www.yslb.jp/skincare/skincare-category/skincare-uv-protection/airthin-uv-defender-spf50--pa----/WW-50558YSL.html?source=virtual_beautyzone&campaign=virtual_beautyzone_game_uv50'
    }],

])

