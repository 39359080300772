
import { SceneName } from "@/scene/SceneName";
import { MarkerName } from "./MarkerName";

export interface Marker {
  name: MarkerName
  imgSrc: string
  spX: number
  spY: number
  pcX: number
  pcY: number
  spWidth: number
  spHeight: number
  pcWidth: number
  pcHeight: number
  isTriangle: boolean
}

export class MarkerImpl implements Marker {
  name: MarkerName
  imgSrc: string
  spX: number
  spY: number
  pcX: number
  pcY: number
  spWidth: number
  spHeight: number
  pcWidth: number
  pcHeight: number
  isTriangle: boolean

  constructor(name: MarkerName, imgSrc: string, spX: number, spY: number, pcX: number, pcY: number, spWidth: number, spHeight: number, pcWidth: number, pcHeight: number, isTriangle: boolean) {
    this.name = name
    this.imgSrc = imgSrc
    this.spX = spX
    this.spY = spY
    this.pcX = pcX
    this.pcY = pcY
    this.spWidth = spWidth
    this.spHeight = spHeight
    this.pcWidth = pcWidth
    this.pcHeight = pcHeight
    this.isTriangle = isTriangle
  }
}

export const showPopup = (sceneName: SceneName) : Array<Marker> => {
  switch(sceneName) {
    case SceneName.secondfloorright:
      return secondRightMarkers
    case SceneName.secondfloorleft:
      return secondLeftMarkers
    case SceneName.thirdfloorright:
      return thirdRightMarkers
    case SceneName.thirdfloorleft:
      return thirdLeftMarkers
    case SceneName.fourthfloorright:
      return fourthRightMarkers
    case SceneName.fourthfloorleft:
      return fourthLeftMarkers
    case SceneName.fifthfloorcenter:
      return fifthCenterMarkers
    case SceneName.fifthfloorright:
      return fifthRightMarkers
    case SceneName.fifthfloorleft:
      return fifthLeftMarkers
    default:
      return undefined
  }
}

export const secondRightMarkers: Array<Marker> = [
  {
    name: MarkerName.allHours,
    imgSrc: '../assets/img/common/icon_touch_point_2.png',
    spX: -15,
    spY: -142,
    spWidth: 220,
    spHeight: 530,
    pcX: 50,
    pcY: -300,
    pcWidth: 250,
    pcHeight: 600,
    isTriangle: true
  },
]

export const secondLeftMarkers: Array<Marker> = [
  {
    name: MarkerName.jo1Comment,
    imgSrc: '../assets/img/common/icon_play.png',
    spX: -285,
    spY: -112,
    spWidth: 200,
    spHeight: 430,
    pcX: -280,
    pcY: -260,
    pcWidth: 180,
    pcHeight: 540,
    isTriangle: false
  },
  {
    name: MarkerName.secondLeftBeautyLesson,
    imgSrc: '../assets/img/common/icon_play.png',
    spX: 25,
    spY: -112,
    spWidth: 260,
    spHeight: 430,
    pcX: 80,
    pcY: -220,
    pcWidth: 270,
    pcHeight: 500,
    isTriangle: false
  }
]

export const thirdRightMarkers: Array<Marker> = [
  {
    name: MarkerName.chasePureShotsGame,
    imgSrc: '../assets/img/common/icon_touch_point_2.png',
    spX: -335,
    spY: -142,
    spWidth: 350,
    spHeight: 620,
    pcX: -380,
    pcY: -205,
    pcWidth: 370,
    pcHeight: 600,
    isTriangle: true
  },
]

export const thirdLeftMarkers: Array<Marker> = [
  {
    name: MarkerName.todaysBeautyPower,
    imgSrc: '../assets/img/common/icon_touch_point_2.png',
    spX: -330,
    spY: -110,
    spWidth: 240,
    spHeight: 500,
    pcX: -330,
    pcY: -260,
    pcWidth: 240,
    pcHeight: 520,
    isTriangle: true
  },
  {
    name: MarkerName.thirdLeftBeautyLesson,
    imgSrc: '../assets/img/common/icon_play.png',
    spX: 35,
    spY: -100,
    spWidth: 260,
    spHeight: 500,
    pcX: 80,
    pcY: -230,
    pcWidth: 260,
    pcHeight: 510,
    isTriangle: false
  },
]

export const fourthLeftMarkers: Array<Marker> = [
  {
    name: MarkerName.dance1,
    imgSrc: '../assets/img/common/icon_play.png',
    spX: -255,
    spY: -150,
    spWidth: 270,
    spHeight: 500,
    pcX: -130,
    pcY: -200,
    pcWidth: 270,
    pcHeight: 500,
    isTriangle: false
  },
  {
    name: MarkerName.dance2,
    imgSrc: '../assets/img/common/icon_play.png',
    spX: 50,
    spY: -160,
    spWidth: 285,
    spHeight: 520,
    pcX: 160,
    pcY: -210,
    pcWidth: 305,
    pcHeight: 520,
    isTriangle: false
  },
]


export const fourthRightMarkers: Array<Marker> = [
  {
    name: MarkerName.yslWallPaper,
    imgSrc: '../assets/img/common/icon_touch_point_2.png',
    spX: -380,
    spY: -160,
    spWidth: 280,
    spHeight: 520,
    pcX: -490,
    pcY: -320,
    pcWidth: 350,
    pcHeight: 620,
    isTriangle: true
  },
  {
    name: MarkerName.fourthRightBeautyLesson,
    imgSrc: '../assets/img/common/icon_play.png',
    spX: -80,
    spY: -140,
    spWidth: 260,
    spHeight: 500,
    pcX: -110,
    pcY: -270,
    pcWidth: 280,
    pcHeight: 560,
    isTriangle: false
  },
]

export const fifthCenterMarkers: Array<Marker> = [
  {
    name: MarkerName.libre,
    imgSrc: '../assets/img/common/icon_play.png',
    spX: 100,
    spY: 100,
    spWidth: 20,
    spHeight: 30,
    pcX: 100,
    pcY: 100,
    pcWidth: 20,
    pcHeight: 30,
    isTriangle: false
  },
  {
    name: MarkerName.montpari,
    imgSrc: '../assets/img/common/icon_play.png',
    spX: 150,
    spY: 150,
    spWidth: 20,
    spHeight: 30,
    pcX: 150,
    pcY: 150,
    pcWidth: 20,
    pcHeight: 30,
    isTriangle: false
  },
  {
    name: MarkerName.ymen,
    imgSrc: '../assets/img/common/icon_play.png',
    spX: 200,
    spY: 200,
    spWidth: 20,
    spHeight: 30,
    pcX: 200,
    pcY: 200,
    pcWidth: 20,
    pcHeight: 30,
    isTriangle: false
  },
]

export const fifthRightMarkers: Array<Marker> = [
  {
    name: MarkerName.jo1,
    imgSrc: '../assets/img/common/icon_play.png',
    spX: -285,
    spY: -206,
    spWidth: 565,
    spHeight: 310,
    pcX: -320,
    pcY: -318,
    pcWidth: 565,
    pcHeight: 310,
    isTriangle: false
  },
]

export const fifthLeftMarkers: Array<Marker> = [
  {
    name: MarkerName.spotify,
    imgSrc: '../assets/img/common/icon_touch_point_2.png',
    spX: -120,
    spY: -45,
    spWidth: 490,
    spHeight: 290,
    pcX: -240,
    pcY: -90,
    pcWidth: 490,
    pcHeight: 290,
    isTriangle: true
  },
  {
    name: MarkerName.line,
    imgSrc: '../assets/img/common/icon_touch_point_2.png',
    spX: -370,
    spY: 95,
    spWidth: 220,
    spHeight: 440,
    pcX: -590,
    pcY: 67,
    pcWidth: 270,
    pcHeight: 500,
    isTriangle: true
  },
]
