class SetElement {
    public map: any
    public num: any
    public upElement: HTMLElement
    public downElement: HTMLElement
    public rightElement: HTMLElement
    public leftElement: HTMLElement
    public multi: HTMLElement
    constructor(upElement: HTMLElement, downElement: HTMLElement, rightElement: HTMLElement, leftElement: HTMLElement, multi: HTMLElement) {
        this.map = 0
        this.upElement = upElement
        this.downElement = downElement
        this.rightElement = rightElement
        this.leftElement = leftElement
        this.multi = multi
    }
}
export default SetElement