<script setup>
import * as THREE from "three"
import { onMounted, onUnmounted, ref } from "vue"

const videoWrapper = document.createElement('div')
videoWrapper.id = 'videoWrapper'
// ラッパーのスタイル設定
videoWrapper.width = window.innerWidth

if (navigator.userAgent.match(/(iPhone|iPod|Android.*Mobile)/i)){
  videoWrapper.height = window.innerHeight / 1.5
} else {
  videoWrapper.height = window.innerHeight
}
videoWrapper.style.width = videoWrapper.width + 'px'
videoWrapper.style.height = videoWrapper.height + 'px'
videoWrapper.style.margin = 'auto'
videoWrapper.style.position = 'absolute'
videoWrapper.style.zIndex = '1'
videoWrapper.style.top = 0

const isMobile = ref(false)
let video = undefined
let width = 0
let height = 0

let scene = new THREE.Scene()
let camera = undefined
const renderer = new THREE.WebGL1Renderer({ alpha: true })

const setRenderer = () => {
  renderer.setSize(width, height)
  renderer.domElement.style.margin = 'auto'
  document.body.appendChild(videoWrapper)
  videoWrapper.appendChild(renderer.domElement)
}

const setCamera = () => {
  camera = new THREE.OrthographicCamera(width / -2, width / 2, height / 2, height / -2, 1, 1000)
}

let texture = undefined

// set video texture to plane geometry
const setTexture = () => {
  texture = new THREE.VideoTexture(video)
  texture.minFilter = THREE.LinearFilter
  texture.magFilter = THREE.LinearFilter
  texture.format = THREE.RGBAFormat
  texture.crossOrigin = 'anonymous'
  let videoMaterial = new THREE.MeshBasicMaterial({ alphaTest: 0.5, map: texture, transparent: true,opacity: 1.0, side: THREE.DoubleSide, color: 0xFFFFFF })
  videoMaterial.alphaMap = texture
  let videoObj = new THREE.Mesh(new THREE.PlaneGeometry(width, height), videoMaterial)
  scene.add(videoObj)
  videoObj.visible = true
  camera.position.z = 50
}

const animate = () => {
  requestAnimationFrame(animate)
  renderer.render(scene, camera)
}

// calculate renderer size fitting wrapper
const calculateRendererSize = () => {
  let scaleH = videoWrapper.height / height
  let scaleW = videoWrapper.width / width
  if (width * scaleH > videoWrapper.width) {
    height = height * scaleW
    width = videoWrapper.width
  } else {
    height = videoWrapper.height
    width = width * scaleH
  }
}

// event handling by video loading
const setRendererToDOM = () => {
  getVideoSize()
  calculateRendererSize()
  setCamera()
  setRenderer()
  setTexture()
  animate()
}

// get raw video size
const getVideoSize = () => {
  video = document.getElementById('video_for_canvas')
  width = video.videoWidth
  height = video.videoHeight
  video.play()
}

const checkDevise = () => {
  if (navigator.userAgent.match(/(iPhone|iPod|Android.*Mobile)/i)){
    isMobile.value = true
  } else {
    isMobile.value = false
  }
}

const resizeHandler = () => {
  // Calculate again window size
  videoWrapper.width = window.innerWidth
  if (isMobile.value){
    videoWrapper.height = window.innerHeight / 1.5
  } else {
    videoWrapper.height = window.innerHeight
  }

  // 花火の動画が左右が切れると不自然なので幅に合わせる
  // calculateRendererSize()
  let scaleW = videoWrapper.width / width
  height = height * scaleW
  width = videoWrapper.width

  videoWrapper.style.width = videoWrapper.width + 'px'
  videoWrapper.style.height = videoWrapper.height + 'px'

  renderer.setSize(width, height)
}

onMounted(() => {
  checkDevise()
  window.addEventListener('resize', resizeHandler)
})

onUnmounted(() => {
  cancelAnimationFrame(animate)
  window.removeEventListener('resize', resizeHandler)
  renderer.domElement.remove()
})
</script>

<template>
  <video id="video_for_canvas" autoplay muted loop crossOrigin="anonymous" playsinline @loadeddata="setRendererToDOM">
    <source v-if="isMobile" src="@/assets/video/video_fireworks_sp.mp4">
    <source v-if="!isMobile" src="@/assets/video/video_fireworks_pc.mp4">
  </video>
</template>

<style>
  #video_for_canvas {
    width: 0px;
    height: 0px;
  }
</style>