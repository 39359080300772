import JO1Data from '@/json/jo1.json'

export interface JO1 {
    id: number;
    name: string;
    imgsrc: string;
    audiosrc: string;
    message1; string;
}

export const getJO1 = (id: number) => {
    return JO1Data.find(j =>  j.id === id)
}
   