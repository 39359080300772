export interface BeautyCharge {
  imgSrc: string
  point: number
}

export class BeautyChargeImpl implements BeautyCharge {
  imgSrc: string
  point: number

  constructor(imgSrc: string, point: number) {
    this.imgSrc = imgSrc
    this.point = point
  }
}