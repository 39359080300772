export interface FavoriteProduct {
  id: number
  jaName: string
  imgSrc: string
  url: string
}

export class FavoriteProductImpl implements FavoriteProduct {
  id: number
  jaName: string
  imgSrc: string
  url: string

  constructor(id: number, jaName: string, imgSrc: string, url: string){
    this.id = id
    this.jaName = jaName
    this.imgSrc = imgSrc
    this.url = url
  }
}