interface info {
  img: string,
  src: string,
  text: string,
}

export const arrayInfo: Array<info> = [
  {
    img: "./assets/img/products/10.png",
    src: "https://player.vimeo.com/video/702712425?h=e209baf7f8",
    text: 'フレグランス AD ムービー MON PARIS'
  }, {
    img: "./assets/img/products/6.png",
    src: "https://player.vimeo.com/video/702711486?h=c2c6f630dd",
    text: 'フレグランス AD ムービー LIBRE'
  },
  {
    img: "../assets/img/products/33.png",
    src: "https://player.vimeo.com/video/702711583?h=f847f3b3ef",
    text: 'フレグランス AD ムービー Y MEN'
  },

]
