import { Director } from "./Director"
import { Scene } from "./Scene"
import { SceneParam } from "./SceneParam"

export class LoadSceneImpl implements Scene {
  public param: SceneParam
  private element?: HTMLElement

  public startTime = 0;
  public fontActive = false
  private videoLoaded = false
  private videoElement?: HTMLVideoElement;
  private loadVideo: () => void;
  constructor(param: SceneParam){
    this.param = param
    this.loadVideo = () => {
      console.log('VIDEO LOADED')
      this.videoElement!.autoplay = false
      this.videoLoaded = true  
      this.videoElement.pause() 
      this.videoElement.currentTime = 0
    }
  }

  public init() {
    this.startTime = 0;
  }


  public start(director: Director): void {
    this.updateDirectorCurrentScene(director)
    this.setElement(director.element!)
    this.videoElement = director.videoElement;
    this.videoElement!.autoplay = true    
    director.videoElement!.addEventListener('loadeddata', this.loadVideo)
    setTimeout(() => {
      console.error('video load timeout')
      this.videoLoaded = true
    })
    this.startTime = new Date().getTime();
  }

  public stop() {
    if(this.element) {
      this.element.style.display = "none"
    }
    return;
  }

  private fontLoadingCheck(){
    if(this.fontActive){
      return true
    }
    const html = document.querySelector('html')
    const fontActive = html.classList.contains('wf-active')
    if(!this.fontActive && fontActive){
      console.log('font ACTIVE', fontActive)
      this.fontActive = fontActive
      return true
    } else if(!fontActive){
      console.error('font INACTIVE')
      return false
    }
    return false
  }

  public tick(director: Director) {
    const interval: number = 3000
    const progress: number = new Date().getTime() - this.startTime
    this.fontLoadingCheck()
    if(progress >= interval && this.fontActive) {
      this.videoElement!.removeEventListener('loadeddata', this.loadVideo)
      this.videoElement.currentTime = 0
      this.nextEvent(director)
    }
  }
  private updateDirectorCurrentScene(director: Director) {
    director.currentScene = this
  }

  private nextEvent(director: Director): void {
    if(this.element) {
      this.element.style.display = "none"
    }
    const nextScene = director.findUpScene(this.param);
    director.start(nextScene)
  }

  private setElement(element: HTMLElement) {
    if(element){
      this.element = element
      this.element.style.display = "flex"
    }
  }
}