
import { LoadSceneImpl } from "./LoadScene"
import { SceneParam } from "./SceneParam"
import { StandSceneImpl } from "./StandScene"
import { TransitionSceneImpl } from "./TransitionScene"

export class SceneFactory {
  public static create(s: SceneParam) {
    if(s.scene === 'load') {
      return new LoadSceneImpl(s)
    } else if(s.scene === 'transition') {
      return new TransitionSceneImpl(s)
    } else {
      return new StandSceneImpl(s)
    }
  }
}