import { ShopArea } from "./ShopArea";

export interface ShopInfo {
    shopArea: ShopArea;
    btqUrl: string;
    shopListUrl: string
}

const shopLinks = new Map<ShopArea, ShopInfo>([
    ["WelcomeGift", {
        shopArea: "WelcomeGift",
        btqUrl: 'https://www.yslb.jp/?source=virtual_beautyzone&campaign=virtual_beautyzone_welcomegift_ectop',
        shopListUrl: 'https://www.yslb.jp/stores/store-list/?source=virtual_beautyzone&campaign=virtual_beautyzone_welcomegift_store'
      }
    ],
    ["AllHours", {
        shopArea: "AllHours",
        btqUrl: 'https://www.yslb.jp/?source=virtual_beautyzone&campaign=virtual_beautyzone_ahgwp_ectop',
        shopListUrl: null
      }
    ],
    ["PureShots", {
        shopArea: "PureShots",
        btqUrl: 'https://www.yslb.jp/?source=virtual_beautyzone&campaign=virtual_beautyzone_game_ectop',
        shopListUrl: 'https://www.yslb.jp/stores/store-list/?source=virtual_beautyzone&campaign=virtual_beautyzone_game_store'
      }
    ],
    ["Beauty", {
        shopArea: "Beauty",
        btqUrl: 'https://www.yslb.jp/?source=virtual_beautyzone&campaign=virtual_beautyzone_beautycharge_ectop',
        shopListUrl: 'https://www.yslb.jp/stores/store-list/?source=virtual_beautyzone&campaign=virtual_beautyzone_beautycharge_store'
      }
    ],
]);


export const findShop = (shopArea: ShopArea): ShopInfo => {
    return shopLinks.get(shopArea)
}
