import { MAPINFO, DIRECTION, map, MAPOBJECT } from './setup';
import Monster from './Monster';
import Controller from './Controller';
import Pacman from './Pacman';
import Game from './Game';
import Dots from './Dots';

class Maze {
    public monsters: Array<Monster>
    public controller: Controller
    public pacman: Pacman
    public game: Game
    public dot: Dots
    public ctx: CanvasRenderingContext2D
    private copyMap: number[][]
    private mapObject
    private mapInfo: typeof MAPINFO;
    constructor(game: Game, copyMap: number[][], mapObject: typeof MAPOBJECT, mapInfo: typeof MAPINFO, controller: Controller, ctx: CanvasRenderingContext2D) {
        this.controller = controller
        this.game = game;
        this.copyMap = copyMap;
        this.mapObject = mapObject;
        this.mapInfo = mapInfo;
        this.ctx = ctx;
        this.monsters = [
        ]
        this.pacman = new Pacman(this.controller, this, 2, 11, 0, MAPOBJECT, map, MAPINFO, DIRECTION, this.ctx);
        this.dot = new Dots(this, this.copyMap, MAPOBJECT, mapInfo, this.ctx)
    }

    // 0.5秒ごとに再描画
    tick() {
        this.ctx.clearRect(0, 0, 500, 500)
        this.pacman.delete();
        this.pacman.tick(this.monsters);
        this.dot.tick(this.pacman);
    }
}

export default Maze;
