import Maze from './Maze'
import Score from './Score'
import { map, MAPOBJECT, MAPINFO, DIRECTION } from './setup'
import Controller from './Controller'
import SetElement from './SetElement'



class Game {
  public controller: Controller;
  public maze: Maze | null;
  public score: Score | null;
  public ctx: CanvasRenderingContext2D
  private handler: number;
  private isPaused: boolean
  setElement: SetElement
  gameClear: () => void
  gameOver: () => void
  countPure: () => void
  changeGif: () => void
  countFrame: number
  multi: HTMLElement | null
  constructor(ctx: CanvasRenderingContext2D, gameClear: () => void, gameOver: () => void, setElement: SetElement, countPure: () => void, changeGif: () => void) {
    this.controller = new Controller(DIRECTION)
    this.maze = null;
    this.score = null
    this.handler = -1;
    this.isPaused = false
    this.ctx = ctx;
    this.gameClear = gameClear
    this.gameOver = gameOver
    this.countFrame = 0
    this.setElement = setElement
    this.countPure = countPure
    this.changeGif = changeGif
  }
  start() {
    this.maze = new Maze(this, map, MAPOBJECT, MAPINFO, this.controller, this.ctx)
    this.score = new Score(this.maze, MAPINFO)
    this.maze.pacman.clickChange()
    this.maze.pacman.pressKey()
    this.tick();
  }

  tick() {
    const targetInterval: number = 1000 / 6;
    let prevTime: number = Date.now() - targetInterval;
    const maze: Maze | null = this.maze
    const self = this;
    const score = this.score
    function loop() {
      self.handler = requestAnimationFrame(loop);
      const currentTime: number = Date.now();
      if (currentTime - prevTime > targetInterval) {
        // self.countFrame++
        // console.log(self.countFrame);
        // if (self.countFrame === 240) {
        //     self.over()
        // }
        // console.log('1tick');


        prevTime += targetInterval;
        maze!.tick();
        score!.count()
      }
    }
    loop();
  }

  pause() {
    if (this.isPaused) {
      this.tick()
      this.isPaused = false
    } else {
      window.cancelAnimationFrame(this.handler);
      this.isPaused = true
    }
  }

  // ゲームクリア
  clear() {
    // 通知受けたらクリア
    window.cancelAnimationFrame(this.handler);
    console.log('game clear');
    this.gameClear()
    return
  }
  // ゲームオーバー
  over() {
    // 通知受けたらゲームオーバー
    window.cancelAnimationFrame(this.handler);
    console.log('game over');
    this.gameOver()
    return 'game over'
  }
}

export default Game