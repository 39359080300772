import { ProductArea } from "./ProductArea"
import { Size } from "./Size"
import { ProductJson } from "./ProductJson"
import productsData from '@/json/products.json'

export interface Product {
  id: number
  enName: string
  jaName: string
  sizes: Array<Size>
  imgSrc: string
  areaSrc: string
  description: Map<ProductArea, string>
  url: Map<ProductArea, string>
}

const transSize = (j: ProductJson) => {
  const sizes: Array<Size> = []

  const size1: Size = {
    price: j['price1'],
    capacity: j['capacity1']
  }

  const size2: Size = {
    price: j['price2'],
    capacity: j['capacity2']
  }

  sizes.push(size1, size2)

  return sizes
}

const transDescription = (j: ProductJson) => {
  const description: Map<ProductArea, string> = new Map([
    ['1F', j['description']],
    ['2F', j['description']],
    ['3F', j['description']],
    ['4F', j['description']],
    ['5F', j['description']],
    ['Beauty', j['descriptionBeauty']],
  ])
  return description
}

const transMap = (j: ProductJson) => {
  const url: Map<ProductArea, string> = new Map([
    [j['productArea1'], j['url1']],
    [j['productArea2'], j['url2']],
    [j['productArea3'], j['url3']],
  ])
  return url
}

const transAttribute = (j: ProductJson) => {
  const sizes: Array<Size> = transSize(j)
  const url: Map<ProductArea, string> = transMap(j)
  const description: Map<ProductArea, string> = transDescription(j)

  const transData: Product = {
    id: j['id'],
    enName: j['enName'],
    jaName: j['jaName'],
    description: description,
    sizes: sizes,
    imgSrc: j['imgSrc'],
    areaSrc: j['areaSrc'],
    url: url
  }
  return transData
}


export const allProductList: Product[] = new Array<Product>()
export const allProductsData: Product[] = new Array<Product>()
export const allHoursData: Product[] = new Array<Product>()
export const lipData: Product[] = new Array<Product>()
export const pureShotData: Product[] = new Array<Product>()
export const libreData: Product[] = new Array<Product>()
export const beautyData: Product[] = new Array<Product>()


const init = (data) => {
  data.forEach(d => {
    const transData: Product = transAttribute(d)
    allProductList.push(transData)

    switch(d['productArea1']) {
      case '1F':
        allProductsData.push(transData)
        break;
      case '2F':
        allHoursData.push(transData)
        break;
      case '3F':
        lipData.push(transData)
        break;
      case '4F':
        pureShotData.push(transData)
        break;
      case '5F':
        libreData.push(transData)
        break;
      case 'Beauty':
        beautyData.push(transData)
        break;
      default:
        break;
    }

    switch(d['productArea2']) {
      case '1F':
        allProductsData.push(transData)
        break;
      case '2F':
        allHoursData.push(transData)
        break;
      case '3F':
        lipData.push(transData)
        break;
      case '4F':
        pureShotData.push(transData)
        break;
      case '5F':
        libreData.push(transData)
        break;
      case 'Beauty':
        beautyData.push(transData)
        break;
      default:
        break;
    }

    switch(d['productArea3']) {
      case '1F':
        allProductsData.push(transData)
        break;
      case '2F':
        allHoursData.push(transData)
        break;
      case '3F':
        lipData.push(transData)
        break;
      case '4F':
        pureShotData.push(transData)
        break;
      case '5F':
        libreData.push(transData)
        break;
      case 'Beauty':
        beautyData.push(transData)
        break;
      default:
        break;
    }
  })

  //console.log('allproductsdata', allProductsData)
}

init(productsData)

export const getProduct = (id: number) => {
  return allProductList.find(product => product.id === id)
}