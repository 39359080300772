import { createStore } from "vuex";

export default createStore({
  state: {
    whereToMove: null,
    clickFloorWhereToMove: null,
    isCloseFirstFloor: true,
    isCloseFifthDialog: null,
    isCloseBeautyChargeDialog: null,
  },
  getters: {
    getIsCloseBeautyChargeDialog: state => {
      return state.isCloseBeautyChargeDialog;
    }
  },
  mutations: {
    setWhereToMove(state, whereToMove) {
      state.whereToMove = whereToMove;
    },
    setClickFloorWhereToMove(state, clickFloorWhereToMove) {
      state.clickFloorWhereToMove = clickFloorWhereToMove
    },
    setIsCloseFirstFloor(state, isCloseFirstFloor) {
      state.isCloseFirstFloor = isCloseFirstFloor
    },
    setIsCloseFifthDialog(state, isCloseFifthDialog) {
      state.isCloseFifthDialog = isCloseFifthDialog
    },
    setIsCloseBeautyChargeDialog(state, isCloseBeautyChargeDialog) {
      state.isCloseBeautyChargeDialog = isCloseBeautyChargeDialog
    }
  },
  actions: {},
  modules: {},
});
