import { Place } from "@/product/Place"
import { ProductPlace } from "@/product/ProductPlace"
import { DebugElem } from "./DebugElem"

export class Debug {
  private productPlaces: Array<ProductPlace>
  private _index: number

  debugElem: DebugElem

  constructor(debugElem: DebugElem){
    this._index = 0
    const lastIndex = localStorage.getItem('debug_index')
    if(lastIndex){
      
      this._index = parseInt(lastIndex)
    }
    this.debugElem = debugElem
  }

  public loaded(){
    this.debugElem.elem.style.display = 'block'
    this.setDebugFields()
    const areas = window.document.querySelectorAll<HTMLElement>('.product-area')
    areas.forEach((elem) => {
      elem.classList.add('debug')
    })
    //console.log('debug index', window.localStorage.getItem('debug_index'))
  }

  public setProductPlaces(productPlaces: Array<ProductPlace>){
    this.productPlaces = productPlaces
    if(this._index >= this.productPlaces.length){
      this._index = 0
    }
  }

  public setXValue(sign: number){
    const diff: number = 5
    const value = Number(this.debugElem.posXElem.value)
    const newValue = value + sign * diff
    this.debugElem.posXElem.value = newValue.toString()
    const place = this.setObject()
    return place
  }

  public setYValue(sign: number){
    const diff: number = 5
    const value = Number(this.debugElem.posYElem.value)
    const newValue = value + sign * diff
    this.debugElem.posYElem.value = newValue.toString()
    const place = this.setObject()
    return place
  }

  public setWidthValue(sign: number){
    const diff: number = 1
    const value = Number(this.debugElem.widthElem.value)
    const newValue = value + sign * diff
    this.debugElem.widthElem.value = newValue.toString()
    const place = this.setObject()
    return place
  }

  public setHeightValue(sign: number){
    const diff: number = 1
    const value = Number(this.debugElem.heightElem.value)
    const newValue = value + sign * diff
    this.debugElem.heightElem.value = newValue.toString()
    const place = this.setObject()
    return place
  }

  public setObject(){
    if(this.productPlaces.length < this._index){
      alert('index outbounds')
    }
    const productPlace = this.productPlaces[this._index]
    const place: Place = {
      id: productPlace.param.id,
      spX: Number(this.debugElem.posXElem.value),
      spY: Number(this.debugElem.posYElem.value),
      pcX: Number(this.debugElem.posXElem.value),
      pcY: Number(this.debugElem.posYElem.value),
      spWidth: Number(this.debugElem.widthElem.value),
      spHeight: Number(this.debugElem.heightElem.value),
      pcWidth: Number(this.debugElem.widthElem.value),
      pcHeight: Number(this.debugElem.heightElem.value),
    }
    return place
  }

  public setIndexButtonUp(){
    this.setIndex(this._index + 1)
    window.localStorage.setItem('debug_index', this._index.toString())
  }

  public setIndexButtonDown(){
    this.setIndex(this._index - 1)
    window.localStorage.setItem('debug_index', this._index.toString())
  }

  private setDebugFields(){
    const productPlace = this.productPlaces[this._index]
    this.debugElem.imageElem.src = productPlace.param.imgSrc
    this.debugElem.nameElem.innerHTML = productPlace.param.enName
    this.debugElem.indexElem.value = productPlace.param.id.toString()
    if(navigator.userAgent.match(/(iPhone|iPod|Android.*Mobile)/i)){
      this.debugElem.posXElem.value = productPlace.param.spX.toString()
      this.debugElem.posYElem.value = productPlace.param.spY.toString()
      this.debugElem.widthElem.value = productPlace.param.spWidth.toString()
      this.debugElem.heightElem.value = productPlace.param.spHeight.toString()
    } else {
      this.debugElem.posXElem.value = productPlace.param.pcX.toString()
      this.debugElem.posYElem.value = productPlace.param.pcY.toString()
      this.debugElem.widthElem.value = productPlace.param.pcWidth.toString()
      this.debugElem.heightElem.value = productPlace.param.pcHeight.toString()
    }
  }

  private setIndex(i: number){
    if(i > this.productPlaces.length - 1 || i < 0){
      alert('index outbounds')
      return
    }

    this._index = i
    
    this.setDebugFields()
  }
}