export const snsText = (url) => {
    const text = `【BEAUTY POWER OF TODAY】
バーチャルイベント
"YSL BEAUTY ZONE TOKYO"
限定コンテンツが登場。

3つの質問に答えて、今日のあなたにぴったりのビューティパワーメッセージを手にいれて。
ジャパンアンバサダーのJO1が音声でお届け。`
    return text;
}

export const twitterText = () => {
    return `【BEAUTY POWER OF TODAY】
バーチャルイベント
"YSL BEAUTY ZONE TOKYO"
限定コンテンツが登場。

3つの質問に答えて、今日のあなたにぴったりのビューティパワーメッセージを手にいれて。
ジャパンアンバサダーのJO1が音声でお届け。`
}

