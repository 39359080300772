import { BeautyCharge } from './BeautyCharge'
import { BeautyChargeImpl } from './BeautyCharge'
import { FloorName } from '@/floor/FloorName'


export class BeautyChargeCounter {
  beautyCharges: Array<BeautyCharge>
  floorNames: Array<FloorName>
  constructor() {
    this.init()
  }

  public charge(floorName: FloorName) {
    const ret = this.floorNames.find(f => f === floorName)
    //console.log('floor name', ret, floorName)
    if(!ret){
      this.floorNames.push(floorName)
    }
    //console.log(this.floorNames)
    return this.floorNames.length
  }

  public count() {
    return this.floorNames.length
  }
  public get() {
    let beautycharge = this.beautyCharges.find(b => this.count() === b.point)
    if(!beautycharge){
      beautycharge = beautyChargeParams[0] // 1階に到着直後にはcountがないので、その場合でも1はあるという判定
    }
    return beautycharge
  }

  public init() {
    //console.log('Beauty chage init')
    this.beautyCharges = beautyChargeParams.map(b => new BeautyChargeImpl(b.imgSrc, b.point))
    this.floorNames = []
  }
}

const beautyChargeParams : BeautyCharge[] = [
  {
    imgSrc: './assets/img/common/icon_bc1.png',
    point: 1
  },
  {
    imgSrc: './assets/img/common/icon_bc2.png',
    point: 2
  },
  {
    imgSrc: './assets/img/common/icon_bc3.png',
    point: 3
  },
  {
    imgSrc: './assets/img/common/icon_bc4.png',
    point: 4
  },
  {
    imgSrc: './assets/img/common/icon_bc5.png',
    point: 5
  },
]