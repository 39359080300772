export enum MarkerName {
  allHours = 'allHours',
  announcePopup = 'announcePopup',
  jo1Comment = 'jo1Comment',
  secondLeftBeautyLesson = 'secondLeftBeautyLesson',
  chasePureShotsGame = 'chasePureShotsGame',
  todaysBeautyPower = 'todaysBeautyPower',
  tbp = 'tbp',
  thirdLeftBeautyLesson = 'thirdLeftBeautyLesson',
  dance1 = 'dance1',
  dance2 = 'dance2',
  yslWallPaper = 'yslWallPaper',
  fourthRightBeautyLesson = 'fourthRightBeautyLesson',
  libre = 'libre',
  montpari = 'montpari',
  ymen = 'ymen',
  jo1 = 'jo1',
  spotify = 'spotify',
  line = 'line'
}
