import { FloorName } from "./FloorName";
import { SceneName } from "@/scene/SceneName";
interface FloorText {
  text: string;
  en_text: string;
}
export interface FloorInfo {
  floor: FloorName;
  name: string;
  en_name: string;
  scene: SceneName;
  imagePath: string;
  iconPath: string;
  texts: Array<FloorText>;
}

const floors = new Map<FloorName, FloorInfo>([
  ["1F", {
    floor: "1F",
    name: "エントランスホール",
    en_name: 'ENTRANCE HALL',
    scene: SceneName.firstfloorcenter,
    imagePath: "./assets/img/floormap/icon_map1f.png",
    iconPath: "./assets/img/floormap/icon_menu1f.png",
    texts: [
      {
        text: 'YSLアイコン製品',
        en_text: 'ALL PRODUCTS'
      },
      {
        text: 'ウェルカム ギフト',
        en_text: 'WELCOME GIFT'
      }
    ]
  }],
  ["2F", {
    floor: "2F",
    name: "オールアワーズ リキッド",
    en_name: "ALL HOURS LIQUID",
    scene: SceneName.secondfloorcenter,
    imagePath: "./assets/img/floormap/icon_map2f.png",
    iconPath: "./assets/img/floormap/icon_menu2f.png",
    texts: [
      {
        text: 'オールアワーズ リキッド＆関連製品',
        en_text: 'ALL HOURS LIQUID＆+1 ITEM'
      },
      {
        text: 'オールアワーズ リキッド 製品情報',
        en_text: 'ALL HOURS GRAM'
      },
      {
        text: 'オールアワーズ リキッドの使い方をチェック',
        en_text: 'BEAUTY LESSON -ALL HOURS LIQUID-'
      },
      {
        text: 'JO1による製品レビュー',
        en_text: 'JO1 MEETS ALL HOURS LIQUID'
      },
    ]
  }],
  ["3F", {
    floor: "3F",
    name: "ピュアショット ＆ゲーム",
    en_name: "PURE SHOTS & GAME",
    scene: SceneName.thirdfloorcenter,
    imagePath: "./assets/img/floormap/icon_map3f.png",
    iconPath: "./assets/img/floormap/icon_menu3f.png",
    texts: [
      {
        en_text: 'PURE SHOTS',
        text: 'ピュアショット'
      },
      {
        en_text: 'CHASE PURE SHOTS GAME',
        text: 'ゲーム。クリアでスぺシャル特典GET'
      },
      {
        en_text: 'BEAUTY POWER OF TODAY',
        text: '今日のパワーメッセージをJO1が音声でお届け。'
      },
      {
        en_text: 'BEAUTY LESSON -PURE SHOTS-',
        text: 'ピュアショットの使い方をチェック'
      },
    ]
  }],
  ["4F", {
    floor: "4F",
    name: "リップ ＆ダンス",
    en_name: "LIP & DANCE",
    scene: SceneName.fourthfloorcenter,
    imagePath: "./assets/img/floormap/icon_map4f.png",
    iconPath: "./assets/img/floormap/icon_menu4f.png",
    texts: [
      {
        text: 'ルージュ ヴォリュプテ キャンディグレーズ',
        en_text: 'ROUGE VOLUPTÉ CANDY GLAZE'
      },
      {
        text: 'YSL BEAUTY ZONE オリジナル壁紙をGET',
        en_text: 'WALLPAPER'
      },
      {
        text: '【DANCERS】限定スペシャルムービー',
        en_text: 'PUSH THE BOUNDARIES'
      },
      {
        text: 'ルージュ ヴォリュプテ キャンディグレーズの使い方をチェック',
        en_text: 'BEAUTY LESSON -CANDY GLAZE-'
      },
    ]
  }],
  ["5F", {
    floor: "5F",
    name: "ルーフトップ - <br/>フレグランス＆ミュージック",
    en_name: "ROOFTOP<br/>FRAGRANCE & MUSIC-​",
    scene: SceneName.fifthfloorcenter,
    imagePath: "./assets/img/floormap/icon_map5f.png",
    iconPath: "./assets/img/floormap/icon_menu5f.png",
    texts: [
      {
        en_text: 'FRAGRANCE',
        text: 'YSL アイコン フレグランス'
      },
      {
        en_text: 'JO1 × YSL BEAUTY FOR ALL HOURS',
        text: 'JO1 限定スペシャルムービー'
      },
      {
        en_text: 'YSL BEAUTY ZONE TOKYO MIX​',
        text: 'YSL BEAUTY ZONE TOKYO限定プレイリスト公開'
      },
      {
        en_text: 'FRAGRANCE MOVIE THEATER',
        text: 'フレグランス AD ムービー'
      },
    ]
  }],

]);


export const findFloor = (floorName: FloorName): FloorInfo => {
  return floors.get(floorName)
}

export const AllFloors = (['5F', '4F', '3F', '2F', '1F'] as Array<FloorName>).map(f => {
  return findFloor(f)
})
