//import { diagnosisRawData, DiagnosisNode } from './DiagnosisData'
import diagnosisRawData from '@/json/diagnosis_raw.json' 

export interface Diagnosis {
    id: number;
    text: string;
    type: string;
    answer: string;
    parent: number;
    jo1_id?: number;
    product_id?: number;
    message1?: string;
    children: Diagnosis[];
}
export const DiagnosisData : Diagnosis[] = new Array<Diagnosis>()

// interface guard

const init = (data) => {
    data.forEach(d => {
        d.children = []
        DiagnosisData.push(d)
    })
    DiagnosisData.forEach(d => {
        if(d.type === 'question'){
            d.children = DiagnosisData.filter(d2 => d2.parent === d.id)
        }
    })
}

init(diagnosisRawData)
