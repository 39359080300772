import { Product, allProductsData, allHoursData, lipData, pureShotData, libreData } from "./Product"
import { Place, allProductsPlace, allHoursPlace, lipPlace, pureShotPlace, librePlace } from "./Place"
import { SceneName } from "@/scene/SceneName"
import { ProductArea } from "./ProductArea"
import { Size } from "./Size"

export interface ProductPlaceParam {
  id: number
  enName: string
  jaName: string
  sizes: Array<Size>
  imgSrc: string
  areaSrc: string
  description: Map<ProductArea, string>
  url: Map<ProductArea, string>
  spX: number
  spY: number
  pcX: number
  pcY: number
  spWidth: number
  spHeight: number
  pcWidth: number
  pcHeight: number
}
export interface ProductPlace {
  param: ProductPlaceParam
}

export class ProductPlaceImpl implements ProductPlace {
  param: ProductPlaceParam

  constructor(param: ProductPlaceParam) {
    this.param = param
  }
}

const merge = (products: Array<Product>, places: Array<Place>) => {
  const productPlaces = products.map(p => {
    const place : Place = places.find(s => s.id === p.id)
    const productPlace : ProductPlaceParam = Object.assign(p, place)
    return new ProductPlaceImpl(productPlace)
  })
  return productPlaces
}

export const showProduct = (sceneName: SceneName) : Array<ProductPlace> => {
  switch(sceneName) {
    case SceneName.firstfloorcenter:
      const allProducts : Array<ProductPlace> = merge(allProductsData, allProductsPlace)
      return allProducts
    case SceneName.secondfloorcenter:
      const allHours: Array<ProductPlace> = merge(allHoursData, allHoursPlace)
      return allHours
    case SceneName.thirdfloorcenter:
      const lips: Array<ProductPlace> = merge(lipData, lipPlace)
      return lips
    case SceneName.fourthfloorcenter:
      const pureShots: Array<ProductPlace> = merge(pureShotData, pureShotPlace)
      return pureShots
    case SceneName.fifthfloorcenter:
      const libres: Array<ProductPlace> = merge(libreData, librePlace)
      return libres
    default:
      return undefined
  }
}