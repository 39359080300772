import { MAPINFO, DIRECTION, map, MAPOBJECT } from './setup';
import MoveCharacter from './MoveCharacter';
import Maze from './Maze';
import Monster from './Monster'
import Controller from './Controller';
import { timers } from 'jquery';

class Pacman extends MoveCharacter {
  public maze: Maze;
  public r: number;
  private key: string;
  public ctx: CanvasRenderingContext2D
  public controller: Controller;
  public isCollisioned: boolean
  public nextDir: number;
  public prevDir: number;
  public isStop: string;
  public tickCount: number
  img: any
  constructor(controller: Controller, maze: Maze, mapX: number, mapY: number, dir: number, mapObject: typeof MAPOBJECT, copyMap: number[][], mapInfo: typeof MAPINFO, directionName: typeof DIRECTION, ctx: CanvasRenderingContext2D,) {
    super(mapX, mapY, dir, mapObject, copyMap, mapInfo, directionName);
    this.controller = controller
    this.maze = maze;
    this.r = 15;
    this.key = 'stop';
    this.speed = 1;
    this.isCollisioned = false;
    this.nextDir = 0;
    this.prevDir = 0;
    this.ctx = ctx
    this.tickCount = 0
    this.img = new Image()
    this.img.src = '../../../../assets/img/3F/icon_shopping_bag.png'
  }
  tick(monsters: Array<Monster>) {
    if (this.dir !== 0) {
      this.maze.game.changeGif()
    }
    this.tickCount++
    this.changeDirection();
    this.isTrunAround() //3方向以上に壁がない場合keyの値を変更
    this.checkNextDirWall(this.mapX, this.mapY);
    this.checkWall(this.mapX, this.mapY);
    this.move("パックマン");
    this.checkAround();
    this.draw();
  }

  draw() {
    const x: number = this.mapX * this.mapInfo.tile
    const y: number = this.mapY * this.mapInfo.tile
    this.ctx.drawImage(this.img, x, y, 38, 38)
  }

  delete() {
    this.ctx.clearRect(this.mapX * this.mapInfo.tile, this.mapY * this.mapInfo.tile, this.r * 2, this.r * 2)
  }

  clickChange() {
    const self = this
    const up = this.maze.game.setElement.upElement
    const down = this.maze.game.setElement.downElement
    const left = this.maze.game.setElement.leftElement
    const right = this.maze.game.setElement.rightElement
    up.addEventListener('click', function () {
      console.log('up');
      self.nextDir = self.directionName.up
      self.key = 'none'
      self.tickCount = 0
    })
    down.addEventListener('click', function () {
      console.log('down');
      self.nextDir = self.directionName.down
      self.key = 'none'
      self.tickCount = 0
    })
    left.addEventListener('click', function () {
      console.log('left');
      self.nextDir = self.directionName.left
      self.key = 'none'
      self.tickCount = 0
    })
    right.addEventListener('click', function () {
      console.log('right');
      self.nextDir = self.directionName.right
      self.key = 'none'
      self.tickCount = 0
    });

  }

  checkNextDirWall(x: number, y: number) {
    let nextX: number = x
    let nextY: number = y
    switch (this.nextDir) {
      case this.directionName.up:
        nextY -= this.speed;
        break;
      case this.directionName.down:
        nextY += this.speed;
        break;
      case this.directionName.left:
        nextX -= this.speed;
        break;
      case this.directionName.right:
        nextX += this.speed;
        break;
      case this.directionName.stay:
        break;
    }
    if (this.copyMap[nextY][nextX] !== this.mapObject.wall) {
      this.dir = this.nextDir
    }
  }
  pressKey() {
    const keydownfunc = (e: KeyboardEvent) => {
      this.key = e.key
      this.tickCount = 0
    }
    addEventListener("keydown", keydownfunc)
  }

  changeDirection() {
    if (this.key !== 'none') {
      switch (this.key) {
        case 'ArrowRight':
          this.nextDir = this.directionName.right;
          break;
        case 'ArrowUp':
          this.nextDir = this.directionName.up;
          break;
        case 'ArrowLeft':
          this.nextDir = this.directionName.left;
          break;
        case 'ArrowDown':
          this.nextDir = this.directionName.down;
          break;
        case 'none':
          this.nextDir = this.directionName.stay;
          break;
      }
    }

  }
  isTrunAround() {
    let nextX: number = this.mapX
    let nextY: number = this.mapY
    switch (this.nextDir) {
      case this.directionName.up:
        nextY -= this.speed;
        break;
      case this.directionName.down:
        nextY += this.speed;
        break;
      case this.directionName.left:
        nextX -= this.speed;
        break;
      case this.directionName.right:
        nextX += this.speed;
        break;
      case this.directionName.stay:
        break;
    }
    const dirUp: number = this.copyMap[this.mapY - 1][this.mapX]
    const dirDown: number = this.copyMap[this.mapY + 1][this.mapX]
    const dirLeft: number = this.copyMap[this.mapY][this.mapX - 1]
    const dirRight: number = this.copyMap[this.mapY][this.mapX + 1]
    const dirArray: number[] = [dirUp, dirDown, dirLeft, dirRight];
    const wallObject = this.mapObject.wall
    const wallCount: number = dirArray.filter(function (wall) {
      return wall === wallObject
    }).length;
    // 三方向以上に壁がなく、パックマンが止まっていない場合
    if (wallCount < 2 && this.dir !== this.directionName.stay && this.tickCount > 2) {
      this.nextDir = this.directionName.stay
      this.key = 'none'
    }

  }

}
export default Pacman