import Maze from './Maze'
import { MAPINFO, DIRECTION, map, MAPOBJECT } from './setup';

class Score {
    public maze: Maze
    public mapInfo: typeof MAPINFO
    public countScore: number
    constructor(maze: Maze, mapInfo: typeof MAPINFO) {
        this.maze = maze
        this.mapInfo = mapInfo
        this.countScore = 0
    }
    count() {
        this.countScore = this.maze.dot.count()
    }
    result() {
        return this.countScore
    }
}
export default Score 