import { Scene, JsonParam } from "./Scene"
import { SceneName } from './SceneName'
import { SceneParam } from "./SceneParam"
import { DirectionType } from "./DirectionType"

import { SceneFactory } from "./SceneFactory"
import { FloorName } from "@/floor/FloorName"
import { ProductArea } from "@/product/ProductArea"
import Scenes from '@/json/scenes.json'


export class Director {
  scenes: Scene[]
  changeScene: (scene: SceneParam) => void
  element?: HTMLElement
  videoSectionElement?: HTMLElement
  videoElement?: HTMLVideoElement
  currentScene?: Scene
  handler: number
  constructor(scenes: Array<Scene>, changeScene: (scene: SceneParam) => void, element?: HTMLElement, videoSectionElement?: HTMLElement, videoElement?: HTMLVideoElement, currentScene?: Scene) {
    this.scenes = scenes
    this.element = element
    this.videoSectionElement = videoSectionElement
    this.videoElement = videoElement
    this.currentScene = currentScene
    this.loop()
    this.changeScene = changeScene
  }

  public loop() {
    const loop_ = () => {
      this.handler = requestAnimationFrame(loop_);
      if (this.currentScene) {
        this.currentScene.tick(this)
      }
    }
    loop_()
  }

  public start(scene?: Scene) {
    this.stop()
    if (scene) {
      scene.start(this)
    }
  }

  public stop() {
    if (!this.currentScene) {
      return
    }
  }

  public static init() {
    return Director.fetch()
  }

  public setElement(element: HTMLElement) {
    this.element = element
  }

  public setVideoElement(videoSectionElement: HTMLElement, videoElement: HTMLVideoElement) {
    this.videoSectionElement = videoSectionElement
    this.videoElement = videoElement
  }

  public findUpScene(param: SceneParam) {
    const upScene = this.scenes.find(s => param.up === s.param.name)
    return upScene
  }

  public findDownScene(param: SceneParam) {
    const downScene = this.scenes.find(s => param.down === s.param.name)
    return downScene
  }

  public findRightScene(param: SceneParam) {
    const rightScene = this.scenes.find(s => param.right === s.param.name)
    return rightScene
  }

  public findLeftScene(param: SceneParam) {
    const leftScene = this.scenes.find(s => param.left === s.param.name)
    return leftScene
  }

  public findScene(direction: DirectionType) {
    const sceneParam = this.currentScene!.param
    let scene = undefined
    switch (direction) {
      case 'left':
        scene = this.findLeftScene(sceneParam)
        break;
      case 'right':
        scene = this.findRightScene(sceneParam)
        break;
      case 'up':
        scene = this.findUpScene(sceneParam)
        break;
      case 'down':
        scene = this.findDownScene(sceneParam)
        break;
    }
    if (scene) {
      this.start(scene)
    }
  }

  public stopLoadScene() {
    const load = this.scenes[0] // cheat
    load.stop()
  }

  // 特定のSceneに移動する
  public gotoScene(sceneName: SceneName) {
    const scene = this.scenes.find(s => s.param.name === sceneName)
    if (scene) {
      this.start(scene)
    } else {
      console.error('Scene missing', sceneName)
    }
  }

  public static fetch() {
    const transToEnum = (j: JsonParam): SceneParam => {
      const s: SceneParam = {
        name: SceneName[j.name],
        startTime: j.startTime,
        endTime: j.endTime,
        scene: j.scene,
        floor: j.floor as FloorName,
        productArea: j.productArea as ProductArea,
        up: SceneName[j.up],
        down: SceneName[j.down],
        right: SceneName[j.right],
        left: SceneName[j.left],
      }
      return s
    }

    const getList = (json: Array<JsonParam>) => {
      //console.log(json)
      return json.map(j => {
        const s: SceneParam = transToEnum(j)
        return SceneFactory.create(s)
      })
    }

    return getList(Scenes)
    }
}