import goodsInfo from '@/json/allhours.json'

interface Src {
    vimeo: boolean,
    src: string
}
interface goods {
    imgSrc: Array<string>,
    text: string,
    shareText: string,
    hashtags: string,
    url: string,
    isLike: boolean,
    cartUrl: string
}

// const goodsInfo: Array<goods> = [
//     {
//         imgSrc: [
//             './assets/img/img_welcome_gift.jpg',
//             './assets/img/img_welcome_gift.jpg',
//             'https://player.vimeo.com/video/702713884?h=007bfa4e73'
//         ],
//         text: 'サンプルテキスト',
//         shareText: 'shareText',
//         hashtags: '#hashtag',
//         url: 'https://ejje.weblio.jp/content/test',
//         isLike: false
//     },
//     {
//         imgSrc: [
//             './assets/img/common/icon_logo.png',
//             './assets/img/common/icon_logo.png',
//             'https://player.vimeo.com/video/702713884?h=007bfa4e73&controls=0'
//         ],
//         text: 'サンプルテキスト',
//         shareText: 'shareText',
//         hashtags: '#hashtag',
//         url: 'https://ejje.weblio.jp/content/test',
//         isLike: false
//     },
//     {
//         imgSrc: [
//             './assets/img/common/icon_logo.png',
//             './assets/img/common/icon_logo.png',
//             'https://player.vimeo.com/video/702713884?h=007bfa4e73&controls=0'
//         ],
//         text: 'サンプルテキスト',
//         shareText: 'shareText',
//         hashtags: '#hashtag',
//         url: 'https://ejje.weblio.jp/content/test',
//         isLike: false
//     },
//     {
//         imgSrc: [
//             './assets/img/common/icon_logo.png',
//             './assets/img/common/icon_logo.png',
//             'https://player.vimeo.com/video/702713884?h=007bfa4e73&controls=0'
//         ],
//         text: 'サンプルテキスト',
//         shareText: 'shareText',
//         hashtags: '#hashtag',
//         url: 'https://ejje.weblio.jp/content/test',
//         isLike: false
//     },
//     {
//         imgSrc: [
//             './assets/img/common/icon_logo.png',
//             './assets/img/common/icon_logo.png',
//             'https://player.vimeo.com/video/702713884?h=007bfa4e73&controls=0'
//         ],
//         text: 'サンプルテキスト',
//         shareText: 'shareText',
//         hashtags: '#hashtag',
//         url: 'https://ejje.weblio.jp/content/test',
//         isLike: false
//     },
//     {
//         imgSrc: [
//             './assets/img/common/icon_logo.png',
//             './assets/img/common/icon_logo.png',
//             'https://player.vimeo.com/video/702713884?h=007bfa4e73&controls=0'
//         ],
//         text: 'サンプルテキスト',
//         shareText: 'shareText',
//         hashtags: '#hashtag',
//         url: 'https://ejje.weblio.jp/content/test',
//         isLike: false
//     },
//     {
//         imgSrc: [
//             './assets/img/common/icon_logo.png',
//             './assets/img/common/icon_logo.png',
//             'https://player.vimeo.com/video/702713884?h=007bfa4e73&controls=0'
//         ],
//         text: 'サンプルテキスト',
//         shareText: 'shareText',
//         hashtags: '#hashtag',
//         url: 'https://ejje.weblio.jp/content/test',
//         isLike: false
//     },
//     {
//         imgSrc: [
//             './assets/img/common/icon_logo.png',
//             './assets/img/common/icon_logo.png',
//             'https://player.vimeo.com/video/702713884?h=007bfa4e73&controls=0'
//         ],
//         text: 'サンプルテキスト',
//         shareText: 'shareText',
//         hashtags: '#hashtag',
//         url: 'https://ejje.weblio.jp/content/test',
//         isLike: false
//     },
// ]


class AllHoursGoods implements goods {
    imgSrc: Array<string>
    text: string
    shareText: string
    hashtags: string
    url: string
    isLike: boolean
    cartUrl: string
    constructor(imgSrc: Array<string>, text: string, shareText: string, hashtags: string, url: string, isLike: boolean, cartUrl: string) {
        this.imgSrc = imgSrc
        this.text = text
        this.shareText = shareText
        this.hashtags = hashtags
        this.url = url
        this.isLike = isLike
        this.cartUrl = cartUrl
    }
    changeLike() {
        return this.isLike = !this.isLike
    }
}

export const arrayGoods = goodsInfo.map(goods => {
    return new AllHoursGoods(goods.imgSrc, goods.text, goods.shareText, goods.hashtags, goods.url, goods.isLike, goods.cartUrl)
})

