import { Ref } from "vue";

const DOWNOCON_HEIGHT  = 20;
export const makeScrollHandler = (scrollElem: Ref<HTMLElement>, downiconEnabled: Ref<boolean>) => {
    return (event:Event) => {
        console.log('scroll height', scrollElem.value.scrollHeight)
        console.log(scrollElem.value.scrollTop + scrollElem.value.clientHeight )
        if(scrollElem.value.scrollHeight >= scrollElem.value.scrollTop + scrollElem.value.clientHeight + DOWNOCON_HEIGHT){
            downiconEnabled.value = true
        } else {
            downiconEnabled.value = false
        }
    }
}