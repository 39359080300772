import { Director } from "./Director"
import { AbstractScene } from "./AbstractScene"
import { SceneParam } from "./SceneParam"

export class TransitionSceneImpl extends AbstractScene {
  constructor(param: SceneParam) {
    super(param)
  }

  public nextEvent(director: Director): void {
//    console.log('transition next', this.param)
    const nextScene = director.findUpScene(this.param)
//    console.log('next->', nextScene.param.name)
    director.start(nextScene)
  }
}
