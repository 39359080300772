export interface Place {
  id: number
  spX: number
  spY: number
  pcX: number
  pcY: number
  spWidth: number
  spHeight: number
  pcWidth: number
  pcHeight: number
}

// memo
// pc w/h 1.2577873254564984
const PC_VIDEO_WIDTH = 1920
const PC_VIDEO_HEIGHT = 1080
export const PC_VIDEO_RATIO = PC_VIDEO_WIDTH / PC_VIDEO_HEIGHT

export const PC_WIDTH = 930
export const PC_HEIGHT = 930
export const PC_RATIO = PC_WIDTH / PC_HEIGHT


export const cropHeightPC = (width: number, height: number) => {
  const ratio = width / height;
  return ratio > PC_VIDEO_RATIO
}



export const pcPosX = (x: number, width: number, height: number) => {
  let x_ = x
  if (cropHeightPC(width, height)) {
    // 縦crop
    // DO NOTHING
    // 設定されているのは比率なので横cropの場合は
    // オリジナルの動画のサイズから求める
    // 
    const diffWidth = (1920 - 1080) / 2
    const pxOriginPos = x * 0.01 * 1080 + diffWidth
    //console.log(diffWidth, pxOriginPos)
    x_ = pxOriginPos / 1920 * 100
  } else {
    // 横crop
    const ratio = width / height //画面の画角の比率
    const widthRatio = width / PC_WIDTH
    const simuWidth = width / widthRatio * ratio
    //console.log('s w', simuWidth)
    const pxOriginWidth = x * 0.01 * PC_WIDTH
    const diffWidth = (simuWidth - PC_WIDTH) / 2
    //console.log('x, orgPos, newPos', x, pxOriginWidth, pxOriginWidth + diffWidth)
    const currentWidth = pxOriginWidth + diffWidth
    //console.log("x diff, new Width, result", x, diffWidth, currentWidth)
    x_ = currentWidth / simuWidth * 100
  }
  return x_
}

export const pcPosY = (y: number, width: number, height: number) => {
  let y_ = y
  console.log('y, height, y-height', y, height, y * height * 0.01)
  if (cropHeightPC(width, height)) {
    // 縦crop
    // const simuMaxHeight = PC_VIDEO_HEIGHT * (width / PC_VIDEO_WIDTH)
    // const heightRatio = height / simuMaxHeight
    // console.log('width, simuMaxHeithg enableRatio', width, simuMaxHeight, height / simuMaxHeight)
    // const beforePosY = y * 0.01 * PC_VIDEO_HEIGHT
    // const afterPosY =  beforePosY - ((PC_VIDEO_HEIGHT * (1 - heightRatio)) / 2)
    // console.log('before/after', beforePosY, afterPosY, afterPosY * heightRatio)
    // y_ = afterPosY  / height * 100
  } else {
    // 横crop
    // DO NOTHING
  }
  return y_
}
export const pcWidth = (w: number, width: number, height: number) => {
  let w_ = w
  if (cropHeightPC(width, height)) {
    // 縦crop
    // DO NOTHING
    const pxOriginWidth = w * 0.01 * PC_WIDTH
    //const diffWidth = (width - PC_WIDTH)
    w_ = pxOriginWidth / width * 100;

  } else {
    // 横crop
    const pxOriginWidth = w * 0.01 * PC_WIDTH
    //const diffWidth = (width - PC_WIDTH)
    w_ = pxOriginWidth / width * 100;
  }
  return w_
}

export const pcHeight = (h: number, width: number, height: number) => {
  let h_ = h
  if (cropHeightPC(width, height)) {
    // 縦crop
  } else {
    // 横crop
    // DO NOTHING
  }
  return h_
}


export const SP_VIDEO_RATIO = 760 / 1624
export const SP_WIDTH = 414
export const SP_HEIGHT = 896
export const SP_RATIO = SP_WIDTH / SP_HEIGHT

export const cropHeightSP = (width: number, height: number) => {
  const ratio = width / height
  return ratio > SP_VIDEO_RATIO
}

export const spPosX = (x: number, width: number, height: number) => {
  let x_ = x
  if (cropHeightSP(width, height)) {
    // const diffX = (width - SP_WIDTH) / 2
    // const posX = x * 0.01 * SP_WIDTH;
    // const newPosX = posX + diffX

    // x_ = newPosX / width * 100;
    // console.log('x, x_,  posX newPosX', x, x_, posX, newPosX)
    // DO NOTHING
  } else {

  }
  return x_
}

export const spPosY = (y: number, width: number, height: number) => {
  let y_ = y
  if (cropHeightSP(width, height)) {
    const displayRatio = SP_WIDTH / width
    const simuHeight = height * displayRatio
    const posY = y * 0.01 * SP_HEIGHT
    const diffY = (simuHeight - SP_HEIGHT) / 2
    const newPosY = posY + diffY
    y_ = newPosY / simuHeight * 100
    console.log('y, y_,  posY newPosY', y, y_, posY, newPosY)
  } else {


  }
  return y_
}

export const spWidth = (w: number, width: number, height: number) => {
  let w_ = w * (width / SP_WIDTH)
  return w_
}

export const spHeight = (h: number, width: number, height: number) => {
  const displayRatio = SP_WIDTH / width
  const simuHeight = height * displayRatio
  let h_ = h * (SP_HEIGHT / simuHeight)
  return h_
}

export const allProductsPlace: Array<Place> = [
  {
    id: 1,
    spX: -85,
    spY: 240,
    pcX: -46,
    pcY: 165,
    spWidth: 204,
    spHeight: 224,
    pcWidth: 117,
    pcHeight: 140,
  },
  {
    id: 3,
    spX: -124,
    spY: -48,
    pcX: -78,
    pcY: -30,
    spWidth: 176,
    spHeight: 272,
    pcWidth: 106,
    pcHeight: 175,
  },
  {
    id: 6,
    spX: -310,
    spY: -48,
    pcX: -205,
    pcY: -30,
    spWidth: 170,
    spHeight: 200,
    pcWidth: 110,
    pcHeight: 130,
  },
  {
    id: 10,
    spX: -265,
    spY: -230,
    pcX: -170,
    pcY: -152,
    spWidth: 130,
    spHeight: 144,
    pcWidth: 78,
    pcHeight: 92,
  },
  {
    id: 14,
    spX: -80,
    spY: -250,
    pcX: -50,
    pcY: -165,
    spWidth: 167,
    spHeight: 207,
    pcWidth: 105,
    pcHeight: 134,
  },
  {
    id: 19,
    spX: 205,
    spY: -95,
    pcX: 148,
    pcY: -60,
    spWidth: 125,
    spHeight: 220,
    pcWidth: 76,
    pcHeight: 140,
  },
  {
    id: 21,
    spX: 66,
    spY: -5,
    pcX: 60,
    pcY: -3,
    spWidth: 130,
    spHeight: 208,
    pcWidth: 60,
    pcHeight: 144,
  },
  {
    id: 27,
    spX: 190,
    spY: 200,
    pcX: 134,
    pcY: 130,
    spWidth: 110,
    spHeight: 144,
    pcWidth: 55,
    pcHeight: 96,
  },
  {
    id: 28,
    spX: 150,
    spY: -215,
    pcX: 104,
    pcY: -142,
    spWidth: 94,
    spHeight: 140,
    pcWidth: 59,
    pcHeight: 110,
  },
  {
    id: 33,
    spX: -257,
    spY: 184,
    pcX: -168,
    pcY: 125,
    spWidth: 124,
    spHeight: 156,
    pcWidth: 80,
    pcHeight: 111,
  },
]

export const allHoursPlace: Array<Place> = [
  {
    id: 1,
    spX: -120,
    spY: -356,
    pcX: -75,
    pcY: -235,
    spWidth: 220,
    spHeight: 256,
    pcWidth: 140,
    pcHeight: 170,
  },
  {
    id: 3,
    spX: -106,
    spY: -96,
    pcX: -70,
    pcY: -65,
    spWidth: 220,
    spHeight: 372,
    pcWidth: 144,
    pcHeight: 246,
  },
  {
    id: 4,
    spX: -255,
    spY: 50,
    pcX: -170,
    pcY: 30,
    spWidth: 150,
    spHeight: 170,
    pcWidth: 100,
    pcHeight: 110,
  },
  {
    id: 5,
    spX: -320,
    spY: -215,
    pcX: -215,
    pcY: -140,
    spWidth: 190,
    spHeight: 208,
    pcWidth: 130,
    pcHeight: 138,
  },
  {
    id: 16,
    spX: 127,
    spY: 56,
    pcX: 80,
    pcY: 35,
    spWidth: 160,
    spHeight: 176,
    pcWidth: 112,
    pcHeight: 120,
  },
  {
    id: 32,
    spX: 125,
    spY: -240,
    pcX: 80,
    pcY: -158,
    spWidth: 192,
    spHeight: 254,
    pcWidth: 133,
    pcHeight: 166,
  },
]

export const lipPlace: Array<Place> = [
  {
    id: 1,
    spX: 65,
    spY: -60,
    pcX: 28,
    pcY: -30,
    spWidth: 196,
    spHeight: 326,
    pcWidth: 108,
    pcHeight: 175,
  },
  {
    id: 12,
    spX: -215,
    spY: -5,
    pcX: -120,
    pcY: -5,
    spWidth: 166,
    spHeight: 264,
    pcWidth: 99,
    pcHeight: 144,
  },
  {
    id: 13,
    spX: -225,
    spY: -330,
    pcX: -130,
    pcY: -180,
    spWidth: 140,
    spHeight: 208,
    pcWidth: 80,
    pcHeight: 120,
  },
  {
    id: 14,
    spX: -85,
    spY: -185,
    pcX: -50,
    pcY: -100,
    spWidth: 166,
    spHeight: 274,
    pcWidth: 88,
    pcHeight: 145,
  },
  {
    id: 15,
    spX: 50,
    spY: -326,
    pcX: 25,
    pcY: -175,
    spWidth: 156,
    spHeight: 154,
    pcWidth: 82,
    pcHeight: 82,
  },
]

export const pureShotPlace: Array<Place> = [
  {
    id: 19,
    spX: -45,
    spY: -205,
    pcX: -24,
    pcY: -135,
    spWidth: 148,
    spHeight: 282,
    pcWidth: 85,
    pcHeight: 188,
  },
  {
    id: 20,
    spX: -140,
    spY: 65,
    pcX: -100,
    pcY: 44,
    spWidth: 140,
    spHeight: 220,
    pcWidth: 100,
    pcHeight: 144,
  },
  {
    id: 21,
    spX: -100,
    spY: -434,
    pcX: -65,
    pcY: -290,
    spWidth: 150,
    spHeight: 200,
    pcWidth: 94,
    pcHeight: 132,
  },
  {
    id: 22,
    spX: -285,
    spY: -185,
    pcX: -190,
    pcY: -122,
    spWidth: 160,
    spHeight: 204,
    pcWidth: 106,
    pcHeight: 136,
  },
  {
    id: 27,
    spX: 145,
    spY: -40,
    pcX: 96,
    pcY: -25,
    spWidth: 124,
    spHeight: 220,
    pcWidth: 84,
    pcHeight: 150,
  },
  {
    id: 28,
    spX: 155,
    spY: -340,
    pcX: 105,
    pcY: -228,
    spWidth: 126,
    spHeight: 220,
    pcWidth: 84,
    pcHeight: 150,
  },
]

export const librePlace: Array<Place> = [
  {
    id: 6,
    spX: -112,
    spY: 10,
    pcX: -75,
    pcY: 10,
    spWidth: 72,
    spHeight: 150,
    pcWidth: 44,
    pcHeight: 100,
  },
  {
    id: 7,
    spX: -12,
    spY: 6,
    pcX: -10,
    pcY: 10,
    spWidth: 70,
    spHeight: 154,
    pcWidth: 46,
    pcHeight: 100,
  },
  {
    id: 8,
    spX: -220,
    spY: -10,
    pcX: -148,
    pcY: -8,
    spWidth: 72,
    spHeight: 168,
    pcWidth: 46,
    pcHeight: 110,
  },
  {
    id: 9,
    spX: 100,
    spY: 15,
    pcX: 67,
    pcY: 10,
    spWidth: 84,
    spHeight: 144,
    pcWidth: 50,
    pcHeight: 100,
  },
  {
    id: 10,
    spX: -160,
    spY: -40,
    pcX: -110,
    pcY: -35,
    spWidth: 68,
    spHeight: 130,
    pcWidth: 48,
    pcHeight: 96,
  },
  {
    id: 33,
    spX: 45,
    spY: -40,
    pcX: 26,
    pcY: -35,
    spWidth: 58,
    spHeight: 130,
    pcWidth: 40,
    pcHeight: 96,
  },
]

