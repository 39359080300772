import { MAPINFO, DIRECTION, map, MAPOBJECT } from './setup';

class Controller {
    public startX: number
    public endX: number
    public startY: number
    public endY: number
    public dir: number
    public directionName: typeof DIRECTION
    constructor(directionName: typeof DIRECTION) {
        this.startX = 0
        this.endX = 0
        this.startY = 0
        this.endY = 0
        this.dir = 0
        this.directionName = directionName
    }
    // swipe() {

    //   const swipeScreen = (e:TouchEvent ) => {
    //     console.log(e.touches[0].pageX);
    //     this.startX=e.touches[0].pageX
    //   }
    //   return this.startX
    // }
    test() {
        this.getEndX();
        this.getStartX();
        this.getFlickDir()
    }

    getEndX() {
        const screen = <HTMLDivElement>document.getElementById("screen");
        console.log(screen);

        const swipeScreen = (e: TouchEvent) => {
            this.endX = e.touches[0].pageX;
            this.endY = e.touches[0].pageY
        }
        screen.addEventListener('touchmove', swipeScreen);
        return
    }

    getStartX() {
        const screen = <HTMLDivElement>document.getElementById("screen");
        const swipeScreen = (e: TouchEvent) => {
            // console.log(e.touches[0].pageX);
            console.log('start');
            this.startX = e.touches[0].pageX;
            this.startY = e.touches[0].pageY;
        }
        screen.addEventListener('touchstart', swipeScreen);
    }

    getFlickDir() {

        const screen = <HTMLDivElement>document.getElementById("screen");
        const swipeScreen = (e: TouchEvent) => {
            const dX = this.endX - this.startX
            const dY = this.endY - this.startY
            const absoluteDisX = Math.abs(dX);
            const absoluteDisY = Math.abs(dY);
            // どの方角でも30以上座標が移動したら方向の変更を行う
            if (absoluteDisX > 30 || absoluteDisY > 30) {
                // 縦のスワイプより横のスワイプ距離が長い場合
                if (absoluteDisX > absoluteDisY) {
                    // 横に動く処理
                    if (dX > 0) {
                        console.log('右');
                        this.dir = this.directionName.right
                    } else {
                        console.log('左');
                        this.dir = this.directionName.left
                    }
                } else {
                    // 違う場合、縦の処理
                    if (dY > 0) {
                        console.log('下');
                        this.dir = this.directionName.down

                    } else {
                        console.log('上');
                        this.dir = this.directionName.up

                    }
                }
            }

        }
        screen.addEventListener('touchend', swipeScreen);

    }

    // flickUp() {

    // }
    // flickDown() {

    // }
    // flickLeft() {

    // }
    // flickRight() {

    // }
}

export default Controller