import { MAPINFO, DIRECTION, map, MAPOBJECT } from './setup';

abstract class MoveCharacter {
    public mapX: number;
    public mapY: number;
    protected dir: number;
    protected speed: number;
    protected mapObject: typeof MAPOBJECT;
    protected copyMap: number[][]
    protected mapInfo: typeof MAPINFO
    protected directionName: typeof DIRECTION
    constructor(mapX: number, mapY: number, dir: number, mapObject: typeof MAPOBJECT, copyMap: number[][], mapInfo: typeof MAPINFO, directionName: typeof DIRECTION) {
        this.mapX = mapX;
        this.mapY = mapY;
        this.dir = dir;
        this.mapObject = mapObject;
        this.copyMap = copyMap
        this.mapInfo = mapInfo
        this.directionName = directionName
        this.speed = 1;
    }
    abstract draw(): void;
    abstract delete(): void;
    abstract changeDirection(): void;

    move(kinds: string) {
        switch (this.dir) {
            case this.directionName.up:
                this.mapY -= this.speed;
                break;
            case this.directionName.down:
                this.mapY += this.speed;
                break;
            case this.directionName.left:
                this.mapX -= this.speed;
                break;
            case this.directionName.right:
                this.mapX += this.speed;
                break;
            case this.directionName.stay:
                break;
        }
    }

    checkWall(x: number, y: number) {
        let nextX: number = x
        let nextY: number = y

        switch (this.dir) {
            case this.directionName.up:
                nextY -= this.speed;
                break;
            case this.directionName.down:
                nextY += this.speed;
                break;
            case this.directionName.left:
                nextX -= this.speed;
                break;
            case this.directionName.right:
                nextX += this.speed;
                break;
            case this.directionName.stay:
                break;
        }
        // 次に動く方向が壁だったらstay
        if (this.copyMap[nextY][nextX] === this.mapObject.wall) {
            this.dir = this.directionName.stay
        }
    }

    checkAround() {
        // 横か縦mapの外に出ないように（現在mapは縦に空きがないのでYは必要ない）
        if (this.mapX < 0) {
            this.mapX = 0
        } else if (this.mapX >= this.mapInfo.beside) {
            this.mapX -= 1
        } else if (this.mapY < 0) {
            this.mapY = 0
        } else if (this.mapY >= this.mapInfo.vertical) {
            this.mapY -= 1
        }
    }

    collision(moveCharacter: MoveCharacter) {
        // 座標の距離を計算して、1より小さかったらtrue
        const distance: number = Math.sqrt(Math.pow(moveCharacter.mapX - this.mapX, 2) + Math.pow(moveCharacter.mapY - this.mapY, 2));
        return distance < 1;
    }

}
export default MoveCharacter;