import { Scene } from "./Scene"
import { Director } from "./Director"
import { SceneParam } from "./SceneParam";


export abstract class AbstractScene implements Scene {
  public param: SceneParam;
  protected videoSectionElement?: HTMLElement
  protected videoElement?: HTMLVideoElement;
  protected handler?: () => void;
  public loop?: number
  public isEnd = false

  protected startTime = 0;
  constructor(param: SceneParam) {
    this.param = param;
  }

  public init() {
    this.startTime = 0;
  }
  public start(director: Director) {
    //alert('start')
    this.updateDirectorCurrentScene(director)
    this.setVideoElement(director.videoSectionElement!, director.videoElement!)
    this.videoElement.play()
    this.startTime = new Date().getTime()
    director.changeScene(this.param)
  }

  public stop() {
    this.isEnd = true;

  }

  public tick(director: Director) {
    const interval: number = (this.param.endTime - this.param.startTime) * 1000
    const progress: number = new Date().getTime() - this.startTime + 0.1
    if (progress >= interval) {
      this.startTime = new Date().getTime()
      this.nextEvent(director);
    }
  }
  public updateDirectorCurrentScene(director: Director) {
    director.currentScene = this
  }

  public abstract nextEvent(director: Director): void;

  public setVideoElement(videoSectionElement: HTMLElement, videoElement: HTMLVideoElement) {
    this.videoSectionElement = videoSectionElement
    this.videoElement = videoElement
    if (this.videoSectionElement) {
      this.videoSectionElement.style.display = "flex"
    }
    if (this.videoElement && this.videoElement instanceof HTMLVideoElement) {
      this.videoElement.currentTime = this.param.startTime
      this.videoElement.play()
    }
  }

  // class methods
}