import Pacman from './Pacman';
import Maze from './Maze'
import { MAPINFO, MAPOBJECT } from './setup';

class Dots {
  private copyMap: number[][];
  private mapObject: typeof MAPOBJECT
  private maze: Maze
  private mapInfo: typeof MAPINFO
  public dotCount: number
  public eatenDot: number
  ctx: CanvasRenderingContext2D
  img: any
  constructor(maze: Maze, copyMap: number[][], mapObject: typeof MAPOBJECT, mapInfo: typeof MAPINFO, ctx: CanvasRenderingContext2D) {
    this.maze = maze
    this.copyMap = JSON.parse(JSON.stringify(copyMap));
    this.mapObject = mapObject
    this.mapInfo = mapInfo
    this.dotCount = 0
    this.ctx = ctx
    this.img = new Image()
    this.img.src = '../../../../assets/img/3F/icon_pureshots.png'
    this.eatenDot = 0
  }

  tick(pacman: Pacman) {
    // this.delete()
    this.eaten(pacman)
    this.draw();
    this.count();
    this.allDotEaten();
    this.maze.pacman.draw()
    // this.maze.pacman.draw()
  }

  draw() {

    for (let y = 0; y < this.copyMap.length; y++) {
      for (let x = 0; x < this.copyMap[y].length; x++) {
        if (this.copyMap[y][x] === this.mapObject.pure) {
          console.log('test');
          this.ctx.drawImage(this.img, this.mapInfo.tile * x + 5, this.mapInfo.tile * y, 20, 40)
        }
      }
    }
  }
  delete() {
    for (let y = 0; y < this.copyMap.length; y++) {
      for (let x = 0; x < this.copyMap[y].length; x++) {
        if (this.copyMap[y][x] === this.mapObject.pure) {
          this.ctx.clearRect(this.mapInfo.tile * x, this.mapInfo.tile * y, 30, 30)
        }
      }
    }

  }
  eaten(pacman: Pacman) {
    if (this.copyMap[pacman.mapY][pacman.mapX] === this.mapObject.pure) {
      this.copyMap[pacman.mapY][pacman.mapX] = this.mapObject.eaten
      this.eatenDot++
      this.maze.game.countPure()
    }
  }

  count() {
    let count = 0
    for (let y = 0; y < this.copyMap.length; y++) {
      for (let x = 0; x < this.copyMap[y].length; x++) {
        if (this.copyMap[y][x] === this.mapObject.eaten) {
          count += 1

        }
      }
    }
    return count
  }

  allDotEaten() {
    for (let y = 0; y < this.copyMap.length; y++) {
      for (let x = 0; x < this.copyMap[y].length; x++) {
        if (this.copyMap[y][x] === this.mapObject.pure) {
          this.dotCount += 1
        }
      }
    }
    if (this.dotCount === 0) {
      setTimeout(() => {
        this.maze.game.clear()
      }, 100)
    }
    this.dotCount = 0
  }

}
export default Dots